/*
	MBIE Building.govt.nz website
	Created: 1 November 2015
	Last updated: See GIT/SVN
  ----------------------------------
	Styles for accessibility

*/


.access {
    position: relative;
    top: auto;
    left: auto;
    visibility: visible;
}
